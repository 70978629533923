<template>
  <div>
    <v-dialog v-model="dialog" max-width="400" persistent>
      <v-card class="text-center">
        <v-card-text class="pa-6">
          <v-icon
            color="primary"
            size="80"
            class="animate__animated animate__rotateIn"
            >mdi-information-outline</v-icon
          >
          <h2 class="text--primary mt-3">Supprimer une notification</h2>
          <p class="mt-5 mb-5">
            Voulez-vous vraiment supprimer cette notification ?
          </p>
          <v-btn
            text
            class="rounded-lg mr-2 text-none"
            large
            @click="notConfirmed()"
          >
            <v-icon left>mdi-close</v-icon> Annuler
          </v-btn>
          <v-btn
            class="rounded-lg text-none"
            large
            color="primary"
            depressed
            @click="confirm()"
            :loading="btnLoading"
          >
            <v-icon left>mdi-check-circle-outline</v-icon> Confirmer
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
  
 <script>
import { HTTP } from "@/http-common";
export default {
  data() {
    return {
      dialog: false,
      item: null,
      btnLoading: false
    };
  },
  methods: {
    open(item) {
      this.item = item;
      this.dialog = true;
    },
    confirm() {
        this.btnLoading = true;
      HTTP.delete("/v1/notices/" + this.item.id)
        .then((res) => {
            this.btnLoading = false;
          this.$emit("refresh");
          this.dialog = false;
          this.$successMessage = "Notification supprimée avec succès"
        

        })
        .catch((err) => {
            this.btnLoading = false;
            this.$errorMessage = "Une erreur s'est produite"
        });
    },
    notConfirmed() {
      this.dialog = false;
      this.item = null;
    },
  },
};
</script>