<template>
  <v-container fluid>
    <div class="main">
      <v-row>
        <v-col>
          <v-spacer></v-spacer>
          <v-btn
            class="gifty white--text rounded-lg"
            @click="addNotice"
            elevation="0"
          >
            <v-icon>mdi-plus</v-icon>
            Nouvelle Notification
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card class="rounded-lg" flat>
            <v-card-title>
              <v-text-field
                v-model="filter.keyword"
                append-icon="mdi-magnify"
                clearable
                dense
                filled
                hide-details
                placeholder="Rechercher..."
                rounded
                single-line
                max-width="200"
              />
              <v-spacer />
              <filter-notice-dialog @filter="handleFilter($event)" />
              <v-btn
                rounded
                @click="refresh"
                elevation="0"
                class="grey--text ml-3"
              >
                <v-icon>mdi-refresh</v-icon>
                Actualiser
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-skeleton-loader
                type="table"
                v-if="isLoading"
              ></v-skeleton-loader>
              <div v-else>
                <div v-if="notices.data.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="font-weight-bold">ID</th>
                          <th class="font-weight-bold">Image</th>
                          <th class="font-weight-bold">Title</th>
                          <th class="font-weight-bold">Title Ar</th>
                          <th class="font-weight-bold">Date de création</th>
                          <th class="font-weight-bold">Date d'envoi</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in notices.data" :key="item.id">
                          <td class="font-weight-bold text-no-wrap">
                            {{ item.id }}
                          </td>

                          <td class="font-weight-bold text-no-wrap">
                            <v-img
                              :src="getImageLink(item.image)"
                              width="30"
                              height="30"
                            ></v-img>
                          </td>

                          <td class="font-weight-bold">
                            {{ item.title }}
                          </td>
                          <td class="font-weight-bold">
                            {{ item.title_ar }}
                          </td>
                          <td class="font-weight-bold text-no-wrap">
                            {{
                              moment(item.created_at).format("YYYY-MM-DD HH:mm")
                            }}
                          </td>
                          <td class="font-weight-bold text-no-wrap">
                            {{ formatSendAt(item) }}
                          </td>
                          
                          <td>
                            <!-- mdi-pencil -->
                            <v-tooltip top color="blue" v-if="item.editable">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  class="blue--text"
                                  icon
                                  v-if="item.editable"
                                  @click="updateNotice(item)"
                                >
                                  <v-icon 
                                    >mdi-pencil</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>Modifier</span>
                            </v-tooltip>

                            <v-tooltip top color="error">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  @click="deleteNotice(item)"
                                >
                                  <v-icon class="error--text"
                                    >mdi-delete</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>Supprimer</span>
                            </v-tooltip>

                            <!-- mdi-content-copy -->
                            <v-tooltip top color="blue">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  @click="clone(item)"
                                >
                                  <v-icon 
                                    >mdi-content-copy</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>Copier</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div class="d-flex mt-4">
                    <span class="mt-2 mr-2">Lignes par page :</span>
                    <v-select
                      filled
                      v-model="filter.size"
                      style="width: 20px"
                      dense
                      :items="[10, 20, 30, 40, 50]"
                      @change="fetchData"
                    ></v-select>
                    <v-spacer />
                    <v-pagination
                      total-visible="6"
                      v-if="notices.total > filter.size"
                      circle
                      v-model="filter.page"
                      :length="Math.ceil(notices.total / filter.size)"
                      @input="fetchData"
                    ></v-pagination>
                  </div>
                </div>

                <div v-else>
                  <div class="text-center">
                    <v-avatar tile size="200">
                      <v-img :src="require('@/assets/database.svg')"></v-img>
                    </v-avatar>
                    <h3 class="text--primary d-block mb-2">OOPS !</h3>
                    <p>Aucun enregistrement correspondant trouvé.</p>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <NoticeForm ref="noticeForm" @refresh="fetchData()" />
    <DeleteNotice ref="deleteNotice" @refresh="refresh()" />
  </v-container>
</template>

<script>
import { HTTP } from "@/http-common";
import NoticeForm from "./components/NoticeForm.vue";

import DeleteNotice from "./components/DeleteNotice.vue";
import moment from "moment";

import FilterNoticeDialog from "./components/FilterNoticeDialog.vue";

export default {
  components: { NoticeForm, DeleteNotice, FilterNoticeDialog },
  data() {
    return {
      isLoading: true,
      filter: {
        size: 10,
        page: 1,
        keyword: "",
      },

      notices: {},
    };
  },
  methods: {
    formatSendAt(item) {
      if (item.send_at) {
        return item.send_day + " " + item.send_hour;
      }
      return "Date de création";
    },

    fetchData() {
      this.isLoading = true;
      HTTP.get("/v1/notices", {
        params: {
          ...this.filter,
        },
      })
        .then((res) => {
          this.notices = res.data.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    refresh() {
      this.filter.page = 1;
      this.filter.keyword = "";
      this.filter.startDate = "";
      this.filter.endDate = "";
      this.filter.sendDate = "";
      this.fetchData();
    },
    addNotice() {
      this.$refs.noticeForm.open();
    },
    updateNotice(item) {
      this.$refs.noticeForm.open(item);
    },
    deleteNotice(item) {
      this.$refs.deleteNotice.open(item);
    },

    clone(item){
      this.$refs.noticeForm.clone(item);
    },

    handleFilter(event) {
      this.filter.startDate = event.startDate;
      this.filter.endDate = event.endDate;
      this.filter.sendDate = event.sendDate;

      this.fetchData();
    },
  },
  computed: {
    keyword() {
      return this.filter.keyword;
    },
  },
  watch: {
    keyword() {
      this.fetchData();
    },
  },

  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
</style>